(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("PropTypes"), require("firebase"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "PropTypes", "firebase", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["Gloom"] = factory(require("React"), require("PropTypes"), require("firebase"), require("ReactDOM"));
	else
		root["Gloom"] = factory(root["React"], root["PropTypes"], root["firebase"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__26__, __WEBPACK_EXTERNAL_MODULE__54__) {
return 