export const SPECIAL_VALUES =
{
  X: 'X = Hexes moved',
  C: 'C = Number of Characters',
  V: 'V = Number of Scouts present',
};

export const MONSTER_STATS = {
  monsters: {
    'Ancient Artillery': {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 0,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 0,
            attack: 3,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 0,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 0,
            attack: 3,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 0,
            attack: 2,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 0,
            attack: 3,
            range: 6,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 0,
            attack: 3,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 0,
            attack: 4,
            range: 6,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 0,
            attack: 4,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 0,
            attack: 4,
            range: 6,
            attributes: [
              '%target% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 0,
            attack: 4,
            range: 6,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 0,
            attack: 4,
            range: 7,
            attributes: [
              '%target% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 0,
            attack: 4,
            range: 6,
            attributes: [],
          },
          elite: {
            health: 16,
            move: 0,
            attack: 5,
            range: 7,
            attributes: [
              '%target% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 0,
            attack: 4,
            range: 7,
            attributes: [],
          },
          elite: {
            health: 20,
            move: 0,
            attack: 5,
            range: 7,
            attributes: [
              '%target% 2',
            ],
          },
        },
      ],
    },
    'Bandit Archer': {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 2,
            attack: 3,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 3,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 3,
            attack: 3,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 8,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 4,
            range: 6,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 4,
            attack: 4,
            range: 6,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 10,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 4,
            attack: 5,
            range: 6,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 13,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [],
          },
          elite: {
            health: 17,
            move: 4,
            attack: 5,
            range: 6,
            attributes: [
              '%poison%',
            ],
          },
        },
      ],
    },
    'Bandit Guard': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 9,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%muddle%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%muddle%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%muddle%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%muddle%',
              '%shield% 3',
            ],
          },
        },
      ],
    },
    'Black Imp': {
      level: [
        {
          level: 0,
          normal: {
            health: 3,
            move: 1,
            attack: 1,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 4,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 4,
            move: 1,
            attack: 1,
            range: 3,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 6,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 5,
            move: 1,
            attack: 1,
            range: 4,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 8,
            move: 1,
            attack: 2,
            range: 4,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 5,
            move: 1,
            attack: 2,
            range: 4,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 8,
            move: 1,
            attack: 3,
            range: 4,
            attributes: [
              'Attackers gain Disadvantage',
              '%poison%',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 7,
            move: 1,
            attack: 2,
            range: 4,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 11,
            move: 1,
            attack: 3,
            range: 4,
            attributes: [
              'Attackers gain Disadvantage',
              '%poison%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 1,
            attack: 2,
            range: 4,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 12,
            move: 1,
            attack: 3,
            range: 5,
            attributes: [
              'Attackers gain Disadvantage',
              '%poison%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 10,
            move: 1,
            attack: 3,
            range: 4,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 14,
            move: 1,
            attack: 4,
            range: 5,
            attributes: [
              'Attackers gain Disadvantage',
              '%poison%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 12,
            move: 1,
            attack: 3,
            range: 4,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 17,
            move: 1,
            attack: 4,
            range: 5,
            attributes: [
              'Attackers gain Disadvantage',
              '%poison%',
            ],
          },
        },
      ],
    },
    'Cave Bear': {
      level: [
        {
          level: 0,
          normal: {
            health: 7,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 9,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 11,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 17,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 13,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 20,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 16,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 21,
            move: 5,
            attack: 5,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 17,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 24,
            move: 5,
            attack: 6,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 19,
            move: 5,
            attack: 5,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 28,
            move: 5,
            attack: 7,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 22,
            move: 5,
            attack: 5,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 33,
            move: 5,
            attack: 7,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
      ],
    },
    'City Archer': {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 1,
            attack: 3,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 1,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 1,
            attack: 3,
            range: 5,
            attributes: [
              '%pierce% 1',
              '%shield% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 1,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 1,
            attack: 4,
            range: 5,
            attributes: [
              '%pierce% 2',
              '%shield% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 6,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 8,
            move: 2,
            attack: 4,
            range: 5,
            attributes: [
              '%pierce% 2',
              '%shield% 2',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 8,
            move: 2,
            attack: 3,
            range: 5,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 4,
            range: 6,
            attributes: [
              '%pierce% 2',
              '%shield% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 2,
            attack: 4,
            range: 5,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 11,
            move: 2,
            attack: 5,
            range: 6,
            attributes: [
              '%pierce% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 9,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 6,
            range: 6,
            attributes: [
              '%pierce% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 10,
            move: 3,
            attack: 4,
            range: 6,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 13,
            move: 3,
            attack: 6,
            range: 7,
            attributes: [
              '%pierce% 3',
              '%shield% 3',
            ],
          },
        },
      ],
    },
    'City Guard': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 6,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 1',
              '%shield% 2',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
              '%shield% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
              '%shield% 3',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 13,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 3',
              '%shield% 3',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 13,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 6,
            range: 0,
            attributes: [
              '%retaliate% 3',
              '%shield% 3',
            ],
          },
        },
      ],
    },
    Cultist: {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 9,
            move: 3,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%curse%',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%curse%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%curse%',
            ],
          },
          elite: {
            health: 18,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%curse%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%curse%',
            ],
          },
          elite: {
            health: 22,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%curse%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%curse%',
            ],
          },
          elite: {
            health: 25,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%curse%',
            ],
          },
        },
      ],
    },
    'Deep Terror': {
      level: [
        {
          level: 0,
          normal: {
            health: 3,
            move: 0,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 5,
            move: 0,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 4,
            move: 0,
            attack: 2,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 6,
            move: 0,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 4,
            move: 0,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 7,
            move: 0,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 5,
            move: 0,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 8,
            move: 0,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 6,
            move: 0,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 9,
            move: 0,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 7,
            move: 0,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
          elite: {
            health: 11,
            move: 0,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 8,
            move: 0,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
          elite: {
            health: 13,
            move: 0,
            attack: 6,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 9,
            move: 0,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
          elite: {
            health: 15,
            move: 0,
            attack: 6,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
      ],
    },
    'Earth Demon': {
      level: [
        {
          level: 0,
          normal: {
            health: 7,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 9,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 12,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 18,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 13,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 20,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%immobilize%',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 15,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%immobilize%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 17,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%immobilize%',
            ],
          },
          elite: {
            health: 25,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%immobilize%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 20,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%immobilize%',
            ],
          },
          elite: {
            health: 27,
            move: 3,
            attack: 6,
            range: 0,
            attributes: [
              '%immobilize%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 22,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%immobilize%',
            ],
          },
          elite: {
            health: 32,
            move: 3,
            attack: 6,
            range: 0,
            attributes: [
              '%immobilize%',
            ],
          },
        },
      ],
    },
    'Flame Demon': {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 2,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 2,
            range: 4,
            attributes: [
              '%flying%',
              '%retaliate% 2: %range% 2',
              '%shield% 4',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 4,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%retaliate% 3: %range% 2',
              '%shield% 4',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%retaliate% 2: %range% 2',
              '%shield% 3',
            ],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 3,
            range: 5,
            attributes: [
              '%flying%',
              '%retaliate% 3: %range% 3',
              '%shield% 4',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 3,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%retaliate% 3: %range% 2',
              '%shield% 3',
            ],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 4,
            range: 5,
            attributes: [
              '%flying%',
              '%retaliate% 4: %range% 3',
              '%shield% 4',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 4,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%retaliate% 3: %range% 2',
              '%shield% 4',
            ],
          },
          elite: {
            health: 6,
            move: 4,
            attack: 4,
            range: 5,
            attributes: [
              '%flying%',
              '%retaliate% 4: %range% 3',
              '%shield% 5',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 4,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%retaliate% 4: %range% 2',
              '%shield% 4',
            ],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 5,
            range: 5,
            attributes: [
              '%flying%',
              '%retaliate% 5: %range% 3',
              '%shield% 5',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 5,
            move: 4,
            attack: 4,
            range: 5,
            attributes: [
              '%flying%',
              '%retaliate% 4: %range% 3',
              '%shield% 4',
            ],
          },
          elite: {
            health: 8,
            move: 4,
            attack: 5,
            range: 6,
            attributes: [
              '%flying%',
              '%retaliate% 5: %range% 4',
              '%shield% 5',
            ],
          },
        },
      ],
    },
    'Frost Demon': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 12,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 14,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 18,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
          elite: {
            health: 20,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
          elite: {
            health: 22,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 14,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
          elite: {
            health: 25,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
      ],
    },
    'Forest Imp': {
      level: [
        {
          level: 0,
          normal: {
            health: 1,
            move: 3,
            attack: 1,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 4,
            move: 3,
            attack: 1,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 2,
            move: 3,
            attack: 1,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 2,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 6,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 3,
            move: 4,
            attack: 2,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 2,
            range: 4,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 1',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 3,
            move: 4,
            attack: 2,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 2,
            range: 4,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 4,
            move: 4,
            attack: 2,
            range: 4,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 8,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 4,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 9,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 6,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%curse%',
              '%shield% 2',
            ],
          },
        },
      ],
    },
    'Giant Viper': {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 3,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 3,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 4,
            move: 3,
            attack: 1,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 7,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 4,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 8,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 6,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 7,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 13,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 8,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 14,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 10,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 17,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
      ],
    },
    'Harrower Infester': {
      level: [
        {
          level: 0,
          normal: {
            health: 6,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 8,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 10,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 17,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 12,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 19,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 12,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 15,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
          elite: {
            health: 22,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 17,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
          elite: {
            health: 26,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
      ],
    },
    Hound: {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 5,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 4,
            move: 4,
            attack: 2,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 6,
            move: 5,
            attack: 2,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 4,
            attack: 2,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 7,
            move: 5,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 4,
            attack: 2,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 8,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 8,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 11,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 12,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 11,
            move: 5,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 15,
            move: 6,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 5,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 15,
            move: 6,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
      ],
    },
    'Inox Archer': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 2,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 8,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 8,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 9,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 4,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 12,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 17,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 19,
            move: 3,
            attack: 5,
            range: 5,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 23,
            move: 3,
            attack: 5,
            range: 5,
            attributes: [
              '%wound%',
            ],
          },
        },
      ],
    },
    'Inox Guard': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 8,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 12,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 17,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 3',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 13,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 19,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 1',
            ],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 19,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%retaliate% 2',
            ],
          },
          elite: {
            health: 23,
            move: 3,
            attack: 6,
            range: 0,
            attributes: [
              '%retaliate% 4',
            ],
          },
        },
      ],
    },
    'Inox Shaman': {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 6,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 9,
            move: 2,
            attack: 2,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 16,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 5,
          normal: {
            health: 13,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 20,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 6,
          normal: {
            health: 15,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 24,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [],
          },
          elite: {
            health: 27,
            move: 4,
            attack: 5,
            range: 4,
            attributes: [],
          },
        },
      ],
    },
    'Living Bones': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 1,
            range: 0,
            attributes: [
              '%target% 2',
            ],
          },
          elite: {
            health: 6,
            move: 4,
            attack: 2,
            range: 0,
            attributes: [
              '%target% 2',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 3,
            attack: 1,
            range: 0,
            attributes: [
              '%target% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 6,
            move: 4,
            attack: 2,
            range: 0,
            attributes: [
              '%target% 3',
              '%shield% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 5,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%target% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 3',
              '%shield% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 7,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%target% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 10,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 3',
              '%shield% 1',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 7,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 3',
              '%shield% 1',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 10,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 11,
            move: 6,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 13,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 14,
            move: 6,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 3',
              '%shield% 2',
            ],
          },
        },
      ],
    },
    'Living Corpse': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 7,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 1,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 9,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 1,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 10,
            move: 1,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 11,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 13,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 17,
            move: 2,
            attack: 6,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 21,
            move: 2,
            attack: 6,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 25,
            move: 2,
            attack: 6,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
        },
      ],
    },
    'Living Spirit': {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 2,
            attack: 2,
            range: 2,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 2,
            move: 2,
            attack: 2,
            range: 2,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 2,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 3,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 4,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 4,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 4',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 4,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 6,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 4',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 4,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 5,
            range: 5,
            attributes: [
              '%flying%',
              '%shield% 4',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 6,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 9,
            move: 4,
            attack: 5,
            range: 5,
            attributes: [
              '%flying%',
              '%shield% 4',
            ],
          },
        },
      ],
    },
    Lurker: {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%target% 2',
            ],
          },
          elite: {
            health: 7,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%shield% 1',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 1',
            ],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 1',
              '%shield% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 9,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 1',
            ],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 2',
              '%shield% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 2',
            ],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 2',
              '%shield% 1',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 10,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 14,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 2',
              '%shield% 1',
            ],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 3',
              '%shield% 1',
            ],
          },
          elite: {
            health: 16,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 4',
              '%shield% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 14,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 3',
              '%shield% 1',
            ],
          },
          elite: {
            health: 18,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%target% 2',
              '%pierce% 4',
              '%shield% 2',
            ],
          },
        },
      ],
    },
    Ooze: {
      level: [
        {
          level: 0,
          normal: {
            health: 4,
            move: 1,
            attack: 2,
            range: 2,
            attributes: [],
          },
          elite: {
            health: 8,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 1,
            attack: 2,
            range: 2,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 9,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [
              '%shield% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 1,
            attack: 2,
            range: 3,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 11,
            move: 1,
            attack: 3,
            range: 3,
            attributes: [
              '%shield% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 1,
            attack: 3,
            range: 3,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 11,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [
              '%poison%',
              '%shield% 1',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 4,
            range: 4,
            attributes: [
              '%poison%',
              '%shield% 1',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 10,
            move: 2,
            attack: 3,
            range: 3,
            attributes: [
              '%poison%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%poison%',
              '%shield% 1',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 2,
            attack: 4,
            range: 3,
            attributes: [
              '%poison%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 16,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%poison%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 14,
            move: 2,
            attack: 4,
            range: 3,
            attributes: [
              '%poison%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 18,
            move: 3,
            attack: 5,
            range: 4,
            attributes: [
              '%poison%',
              '%shield% 2',
            ],
          },
        },
      ],
    },
    'Night Demon': {
      level: [
        {
          level: 0,
          normal: {
            health: 3,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 5,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
          elite: {
            health: 8,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 6,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 7,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
          elite: {
            health: 13,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 8,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
          elite: {
            health: 15,
            move: 5,
            attack: 5,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
          elite: {
            health: 17,
            move: 5,
            attack: 6,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 14,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
          elite: {
            health: 21,
            move: 5,
            attack: 6,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 4,
            attack: 6,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
          elite: {
            health: 21,
            move: 5,
            attack: 8,
            range: 0,
            attributes: [
              'Attackers gain Disadvantage',
            ],
          },
        },
      ],
    },
    'Rending Drake': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 7,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 9,
            move: 5,
            attack: 5,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 7,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 10,
            move: 5,
            attack: 6,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 11,
            move: 6,
            attack: 6,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 10,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 14,
            move: 6,
            attack: 6,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 11,
            move: 5,
            attack: 5,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 15,
            move: 6,
            attack: 7,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 14,
            move: 5,
            attack: 5,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
          elite: {
            health: 18,
            move: 6,
            attack: 7,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
      ],
    },
    'Savvas Icestorm': {
      level: [
        {
          level: 0,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 3,
            attributes: [
              '%pierce% 3',
            ],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 3,
            range: 4,
            attributes: [
              '%pierce% 3',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 10,
            move: 2,
            attack: 2,
            range: 4,
            attributes: [
              '%pierce% 3',
            ],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 3,
            range: 5,
            attributes: [
              '%pierce% 3',
              '%shield% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 12,
            move: 3,
            attack: 2,
            range: 4,
            attributes: [
              '%pierce% 3',
            ],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 3,
            range: 5,
            attributes: [
              '%pierce% 3',
              '%shield% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 12,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [
              '%pierce% 3',
              '%shield% 1',
            ],
          },
          elite: {
            health: 18,
            move: 3,
            attack: 4,
            range: 6,
            attributes: [
              '%pierce% 3',
              '%shield% 1',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 14,
            move: 3,
            attack: 3,
            range: 5,
            attributes: [
              '%pierce% 3',
              '%shield% 1',
            ],
          },
          elite: {
            health: 19,
            move: 4,
            attack: 4,
            range: 6,
            attributes: [
              '%pierce% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [
              '%pierce% 3',
              '%shield% 1',
            ],
          },
          elite: {
            health: 21,
            move: 4,
            attack: 5,
            range: 6,
            attributes: [
              '%pierce% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 5,
            attributes: [
              '%pierce% 3',
              '%shield% 2',
            ],
          },
          elite: {
            health: 23,
            move: 4,
            attack: 6,
            range: 6,
            attributes: [
              '%pierce% 3',
              '%shield% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 17,
            move: 4,
            attack: 4,
            range: 6,
            attributes: [
              '%pierce% 3',
              '%shield% 2',
            ],
          },
          elite: {
            health: 24,
            move: 4,
            attack: 6,
            range: 6,
            attributes: [
              '%pierce% 4',
              '%shield% 3',
            ],
          },
        },
      ],
    },
    'Savvas Lavaflow': {
      level: [
        {
          level: 0,
          normal: {
            health: 8,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 13,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 9,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%wound%',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 18,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 14,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 16,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
            ],
          },
          elite: {
            health: 24,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 18,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
          elite: {
            health: 27,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 20,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
          elite: {
            health: 30,
            move: 4,
            attack: 6,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 24,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
          elite: {
            health: 35,
            move: 4,
            attack: 6,
            range: 0,
            attributes: [
              '%poison%',
              '%wound%',
            ],
          },
        },
      ],
    },
    'Spitting Drake': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%'],
          },
          elite: {
            health: 8,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%'],
          },
        },
        {
          level: 1,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%'],
          },
          elite: {
            health: 9,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 8,
            move: 3,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
          elite: {
            health: 10,
            move: 3,
            attack: 5,
            range: 4,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 8,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
          elite: {
            health: 12,
            move: 3,
            attack: 5,
            range: 5,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 9,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
          elite: {
            health: 14,
            move: 4,
            attack: 5,
            range: 5,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 12,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
          elite: {
            health: 16,
            move: 4,
            attack: 6,
            range: 5,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 13,
            move: 4,
            attack: 5,
            range: 4,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
          elite: {
            health: 19,
            move: 4,
            attack: 6,
            range: 5,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 4,
            attack: 5,
            range: 4,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
          elite: {
            health: 21,
            move: 4,
            attack: 7,
            range: 5,
            attributes: [
              '%flying%',
              '%muddle%',
            ],
          },
        },
      ],
    },
    'Stone Golem': {
      level: [
        {
          level: 0,
          normal: {
            health: 10,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 10,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 10,
            move: 1,
            attack: 3,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 11,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 11,
            move: 1,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 1',
            ],
          },
          elite: {
            health: 14,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 11,
            move: 1,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 15,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%shield% 3',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 12,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 17,
            move: 2,
            attack: 6,
            range: 0,
            attributes: [
              '%shield% 3',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 13,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 19,
            move: 3,
            attack: 6,
            range: 0,
            attributes: [
              '%shield% 3',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 16,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 20,
            move: 3,
            attack: 7,
            range: 0,
            attributes: [
              '%shield% 3',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 16,
            move: 2,
            attack: 5,
            range: 0,
            attributes: [
              '%shield% 3',
            ],
          },
          elite: {
            health: 21,
            move: 3,
            attack: 7,
            range: 0,
            attributes: [
              '%shield% 4',
            ],
          },
        },
      ],
    },
    'Sun Demon': {
      level: [
        {
          level: 0,
          normal: {
            health: 5,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
          elite: {
            health: 9,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 7,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
          elite: {
            health: 12,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 9,
            move: 2,
            attack: 2,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
          elite: {
            health: 13,
            move: 2,
            attack: 4,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 10,
            move: 2,
            attack: 3,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
          elite: {
            health: 15,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
          elite: {
            health: 16,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 1',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 11,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 2',
            ],
          },
          elite: {
            health: 16,
            move: 3,
            attack: 5,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 12,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 2',
            ],
          },
          elite: {
            health: 18,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 2',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 15,
            move: 3,
            attack: 4,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 2',
            ],
          },
          elite: {
            health: 22,
            move: 4,
            attack: 5,
            range: 0,
            attributes: [
              '%flying%',
              'Advantage',
              '%shield% 2',
            ],
          },
        },
      ],
    },
    'Vermling Scout': {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 3,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 4,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 1,
          normal: {
            health: 3,
            move: 3,
            attack: 1,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 2,
          normal: {
            health: 3,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 3,
          normal: {
            health: 5,
            move: 3,
            attack: 2,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 7,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 4,
          normal: {
            health: 6,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 8,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 5,
          normal: {
            health: 8,
            move: 3,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 11,
            move: 4,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 6,
          normal: {
            health: 9,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 12,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
        {
          level: 7,
          normal: {
            health: 11,
            move: 4,
            attack: 3,
            range: 0,
            attributes: [],
          },
          elite: {
            health: 15,
            move: 5,
            attack: 4,
            range: 0,
            attributes: [],
          },
        },
      ],
    },
    'Vermling Shaman': {
      level: [
        {
          level: 0,
          normal: {
            health: 2,
            move: 2,
            attack: 1,
            range: 3,
            attributes: [
              '%shield% 2',
            ],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%shield% 2',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 2,
            move: 2,
            attack: 1,
            range: 3,
            attributes: [
              '%shield% 3',
            ],
          },
          elite: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%shield% 3',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 3,
            move: 2,
            attack: 1,
            range: 4,
            attributes: [
              '%shield% 3',
            ],
          },
          elite: {
            health: 4,
            move: 3,
            attack: 2,
            range: 4,
            attributes: [
              '%shield% 3',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 3,
            move: 2,
            attack: 2,
            range: 4,
            attributes: [
              '%shield% 3',
            ],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [
              '%shield% 3',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 3,
            move: 3,
            attack: 2,
            range: 4,
            attributes: [
              '%muddle%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 5,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [
              '%muddle%',
              '%shield% 4',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 4,
            move: 3,
            attack: 3,
            range: 4,
            attributes: [
              '%muddle%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 6,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%muddle%',
              '%shield% 4',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 5,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%muddle%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 6,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%muddle%',
              '%shield% 5',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 7,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%muddle%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 8,
            move: 3,
            attack: 4,
            range: 4,
            attributes: [
              '%muddle%',
              '%shield% 5',
            ],
          },
        },
      ],
    },
    'Wind Demon': {
      level: [
        {
          level: 0,
          normal: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 1',
            ],
          },
        },
        {
          level: 1,
          normal: {
            health: 3,
            move: 3,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 5,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 2,
          normal: {
            health: 4,
            move: 4,
            attack: 2,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 7,
            move: 5,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 3,
          normal: {
            health: 5,
            move: 4,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 8,
            move: 5,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 4,
          normal: {
            health: 7,
            move: 4,
            attack: 3,
            range: 3,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 8,
            move: 5,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%disarm%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 5,
          normal: {
            health: 9,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 2',
            ],
          },
          elite: {
            health: 11,
            move: 5,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%disarm%',
              '%shield% 2',
            ],
          },
        },
        {
          level: 6,
          normal: {
            health: 10,
            move: 4,
            attack: 3,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 12,
            move: 5,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%disarm%',
              '%shield% 3',
            ],
          },
        },
        {
          level: 7,
          normal: {
            health: 11,
            move: 4,
            attack: 4,
            range: 4,
            attributes: [
              '%flying%',
              '%shield% 3',
            ],
          },
          elite: {
            health: 13,
            move: 5,
            attack: 5,
            range: 4,
            attributes: [
              '%flying%',
              '%disarm%',
              '%shield% 3',
            ],
          },
        },
      ],
    },
  },
  bosses: {
    'Bandit Commander': {
      level: [
        {
          level: 0,
          health: '8xC',
          move: 3,
          attack: 3,
          range: 0,
          special1: [
            'Move to next door and reveal room',
          ],
          special2: [
            'Summon Living Bones',
          ],
          immunities: [
            '%stun%',
            '%immobilize%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '10xC',
          move: 3,
          attack: 3,
          range: 0,
          special1: [
            'Move to next door and reveal room',
          ],
          special2: [
            'Summon Living Bones',
          ],
          immunities: [
            '%stun%',
            '%immobilize%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '12xC',
          move: 4,
          attack: 3,
          range: 0,
          special1: [
            'Move to next door and reveal room',
          ],
          special2: [
            'Summon Living Bones',
          ],
          immunities: [
            '%stun%',
            '%immobilize%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '13xC',
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            'Move to next door and reveal room',
          ],
          special2: [
            'Summon Living Bones',
          ],
          immunities: [
            '%stun%',
            '%immobilize%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '15xC',
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            'Move to next door and reveal room',
          ],
          special2: [
            'Summon Living Bones',
          ],
          immunities: [
            '%stun%',
            '%immobilize%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '16xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            'Move to next door and reveal room',
          ],
          special2: [
            'Summon Living Bones',
          ],
          immunities: [
            '%stun%',
            '%immobilize%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '19xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            'Move to next door and reveal room',
          ],
          special2: [
            'Summon Living Bones',
          ],
          immunities: [
            '%stun%',
            '%immobilize%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '23xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            'Move to next door and reveal room',
          ],
          special2: [
            'Summon Living Bones',
          ],
          immunities: [
            '%stun%',
            '%immobilize%',
            '%curse%',
          ],
          notes: '',
        },
      ],
    },
    'The Betrayer': {
      level: [
        {
          level: 0,
          health: '10xC',
          move: 3,
          attack: 4,
          range: 3,
          special1: [
            'Summon Giant Viper and Fear',
          ],
          special2: [
            'Mind Control',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%stun%',
            '%disarm%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '12xC',
          move: 3,
          attack: 5,
          range: 3,
          special1: [
            'Summon Giant Viper and Fear',
          ],
          special2: [
            'Mind Control',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%stun%',
            '%disarm%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '14xC',
          move: 3,
          attack: 6,
          range: 4,
          special1: [
            'Summon Giant Viper and Fear',
          ],
          special2: [
            'Mind Control',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%stun%',
            '%disarm%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '16xC',
          move: 4,
          attack: 7,
          range: 4,
          special1: [
            'Summon Giant Viper and Fear',
          ],
          special2: [
            'Mind Control',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%stun%',
            '%disarm%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '18xC',
          move: 4,
          attack: 8,
          range: 4,
          special1: [
            'Summon Giant Viper and Fear',
          ],
          special2: [
            'Mind Control',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%stun%',
            '%disarm%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '20xC',
          move: 5,
          attack: 8,
          range: 5,
          special1: [
            'Summon Giant Viper and Fear',
          ],
          special2: [
            'Mind Control',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%stun%',
            '%disarm%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '23xC',
          move: 5,
          attack: 9,
          range: 5,
          special1: [
            'Summon Giant Viper and Fear',
          ],
          special2: [
            'Mind Control',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%stun%',
            '%disarm%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '27xC',
          move: 5,
          attack: 9,
          range: 5,
          special1: [
            'Summon Giant Viper and Fear',
          ],
          special2: [
            'Mind Control',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%stun%',
            '%disarm%',
            '%curse%',
          ],
          notes: '',
        },
      ],
    },
    'Captain of the Guard': {
      level: [
        {
          level: 0,
          health: '7xC',
          move: 2,
          attack: 3,
          range: 0,
          special1: [
            '%heal% 2, Affect self and all allies',
          ],
          special2: [
            'All allies add +1 Attack to all attacks this round',
            '%attack% +1',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '9xC',
          move: 2,
          attack: 3,
          range: 0,
          special1: [
            '%heal% 2, Affect self and all allies',
          ],
          special2: [
            'All allies add +1 Attack to all attacks this round',
            '%attack% +1',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '11xC',
          move: 2,
          attack: 4,
          range: 0,
          special1: [
            '%heal% 2, Affect self and all allies',
          ],
          special2: [
            'All allies add +1 Attack to all attacks this round',
            '%attack% +1',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '14xC',
          move: 3,
          attack: 4,
          range: 0,
          special1: [
            '%heal% 2, Affect self and all allies',
          ],
          special2: [
            'All allies add +1 Attack to all attacks this round',
            '%attack% +1',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '16xC',
          move: 3,
          attack: 5,
          range: 0,
          special1: [
            '%heal% 2, Affect self and all allies',
          ],
          special2: [
            'All allies add +1 Attack to all attacks this round',
            '%attack% +1',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '20xC',
          move: 3,
          attack: 5,
          range: 0,
          special1: [
            '%heal% 2, Affect self and all allies',
          ],
          special2: [
            'All allies add +1 Attack to all attacks this round',
            '%attack% +1',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '21xC',
          move: 4,
          attack: 6,
          range: 0,
          special1: [
            '%heal% 2, Affect self and all allies',
          ],
          special2: [
            'All allies add +1 Attack to all attacks this round',
            '%attack% +1',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '25xC',
          move: 4,
          attack: 6,
          range: 0,
          special1: [
            '%heal% 2, Affect self and all allies',
          ],
          special2: [
            'All allies add +1 Attack to all attacks this round',
            '%attack% +1',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
      ],
    },
    'The Colorless': {
      level: [
        {
          level: 0,
          health: '9xC',
          move: 3,
          attack: 2,
          range: 0,
          special1: [
            '%dark%%use_element%: Summon Night Demon',
            '%invisible%, Self',
          ],
          special2: [
            '%light%%use_element%: Summon Sun Demon',
            '%heal% 4, Self',
            '%shield% 1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%disarm%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '10xC',
          move: 3,
          attack: 3,
          range: 0,
          special1: [
            '%dark%%use_element%: Summon Night Demon',
            '%invisible%, Self',
          ],
          special2: [
            '%light%%use_element%: Summon Sun Demon',
            '%heal% 4, Self',
            '%shield% 1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%disarm%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '11xC',
          move: 4,
          attack: 3,
          range: 0,
          special1: [
            '%dark%%use_element%: Summon Night Demon',
            '%invisible%, Self',
          ],
          special2: [
            '%light%%use_element%: Summon Sun Demon',
            '%heal% 5, Self',
            '%shield% 1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%disarm%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '12xC',
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            '%dark%%use_element%: Summon Night Demon',
            '%invisible%, Self',
          ],
          special2: [
            '%light%%use_element%: Summon Sun Demon',
            '%heal% 5, Self',
            '%shield% 1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%disarm%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '14xC',
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            '%dark%%use_element%: Summon Night Demon',
            '%invisible%, Self',
          ],
          special2: [
            '%light%%use_element%: Summon Sun Demon',
            '%heal% 6, Self',
            '%shield% 1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%disarm%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '15xC',
          move: 4,
          attack: 5,
          range: 0,
          special1: [
            '%dark%%use_element%: Summon Night Demon',
            '%invisible%, Self',
          ],
          special2: [
            '%light%%use_element%: Summon Sun Demon',
            '%heal% 6, Self',
            '%shield% 1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%disarm%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '17xC',
          move: 4,
          attack: 6,
          range: 0,
          special1: [
            '%dark%%use_element%: Summon Night Demon',
            '%invisible%, Self',
          ],
          special2: [
            '%light%%use_element%: Summon Sun Demon',
            '%heal% 7, Self',
            '%shield% 1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%disarm%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '19xC',
          move: 5,
          attack: 7,
          range: 0,
          special1: [
            '%dark%%use_element%: Summon Night Demon',
            '%invisible%, Self',
          ],
          special2: [
            '%light%%use_element%: Summon Sun Demon',
            '%heal% 7, Self',
            '%shield% 1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%disarm%',
          ],
          notes: '',
        },
      ],
    },
    'Dark Rider': {
      level: [
        {
          level: 0,
          health: '9xC',
          move: 2,
          attack: '3+X',
          range: 0,
          special1: [
            '%move% +2',
            '%attack% +0',
          ],
          special2: [
            '%move% +0',
            'Summon Imp',
            '%attack% -1',
          ],
          immunities: [
            '%immobilize%',
            '%poison%',
            '%stun%',
            '%disarm%',
          ],
          notes: 'X = Hexes moved',
        },
        {
          level: 1,
          health: '10xC',
          move: 3,
          attack: '3+X',
          range: 0,
          special1: [
            '%move% +2',
            '%attack% +0',
          ],
          special2: [
            '%move% +0',
            'Summon Imp',
            '%attack% -1',
          ],
          immunities: [
            '%immobilize%',
            '%poison%',
            '%stun%',
            '%disarm%',
          ],
          notes: 'X = Hexes moved',
        },
        {
          level: 2,
          health: '12xC',
          move: 3,
          attack: '3+X',
          range: 0,
          special1: [
            '%move% +2',
            '%attack% +0',
          ],
          special2: [
            '%move% +0',
            'Summon Imp',
            '%attack% -1',
          ],
          immunities: [
            '%immobilize%',
            '%poison%',
            '%stun%',
            '%disarm%',
          ],
          notes: 'X = Hexes moved',
        },
        {
          level: 3,
          health: '13xC',
          move: 3,
          attack: '4+X',
          range: 0,
          special1: [
            '%move% +2',
            '%attack% +0',
          ],
          special2: [
            '%move% +0',
            'Summon Imp',
            '%attack% -1',
          ],
          immunities: [
            '%immobilize%',
            '%poison%',
            '%stun%',
            '%disarm%',
          ],
          notes: 'X = Hexes moved',
        },
        {
          level: 4,
          health: '15xC',
          move: 3,
          attack: '4+X',
          range: 0,
          special1: [
            '%move% +2',
            '%attack% +0',
          ],
          special2: [
            '%move% +0',
            'Summon Imp',
            '%attack% -1',
          ],
          immunities: [
            '%immobilize%',
            '%poison%',
            '%stun%',
            '%disarm%',
          ],
          notes: 'X = Hexes moved',
        },
        {
          level: 5,
          health: '16xC',
          move: 3,
          attack: '5+X',
          range: 0,
          special1: [
            '%move% +2',
            '%attack% +0',
          ],
          special2: [
            '%move% +0',
            'Summon Imp',
            '%attack% -1',
          ],
          immunities: [
            '%immobilize%',
            '%poison%',
            '%stun%',
            '%disarm%',
          ],
          notes: 'X = Hexes moved',
        },
        {
          level: 6,
          health: '16xC',
          move: 4,
          attack: '5+X',
          range: 0,
          special1: [
            '%move% +2',
            '%attack% +0',
          ],
          special2: [
            '%move% +0',
            'Summon Imp',
            '%attack% -1',
          ],
          immunities: [
            '%immobilize%',
            '%poison%',
            '%stun%',
            '%disarm%',
          ],
          notes: 'X = Hexes moved',
        },
        {
          level: 7,
          health: '18xC',
          move: 4,
          attack: '6+X',
          range: 0,
          special1: [
            '%move% +2',
            '%attack% +0',
          ],
          special2: [
            '%move% +0',
            'Summon Imp',
            '%attack% -1',
          ],
          immunities: [
            '%immobilize%',
            '%poison%',
            '%stun%',
            '%disarm%',
          ],
          notes: 'X = Hexes moved',
        },
      ],
    },
    'Elder Drake': {
      level: [
        {
          level: 0,
          health: '11xC',
          move: 0,
          attack: 3,
          range: 0,
          special1: [
            '%attack% +0 %boss-aoe-elder-drake-sp1%',
          ],
          special2: [
            'Summon 2 Zephyrs and %move%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '12xC',
          move: 0,
          attack: 4,
          range: 0,
          special1: [
            '%attack% +0 %boss-aoe-elder-drake-sp1%',
          ],
          special2: [
            'Summon 2 Zephyrs and %move%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '15xC',
          move: 0,
          attack: 4,
          range: 0,
          special1: [
            '%attack% +0 %boss-aoe-elder-drake-sp1%',
          ],
          special2: [
            'Summon 2 Zephyrs and %move%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '16xC',
          move: 0,
          attack: 5,
          range: 0,
          special1: [
            '%attack% +0 %boss-aoe-elder-drake-sp1%',
          ],
          special2: [
            'Summon 2 Zephyrs and %move%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '20xC',
          move: 0,
          attack: 5,
          range: 0,
          special1: [
            '%attack% +0 %boss-aoe-elder-drake-sp1%',
          ],
          special2: [
            'Summon 2 Zephyrs and %move%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '22xC',
          move: 0,
          attack: 6,
          range: 0,
          special1: [
            '%attack% +0 %boss-aoe-elder-drake-sp1%',
          ],
          special2: [
            'Summon 2 Zephyrs and %move%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '27xC',
          move: 0,
          attack: 6,
          range: 0,
          special1: [
            '%attack% +0 %boss-aoe-elder-drake-sp1%',
          ],
          special2: [
            'Summon 2 Zephyrs and %move%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '29xC',
          move: 0,
          attack: 7,
          range: 0,
          special1: [
            '%attack% +0 %boss-aoe-elder-drake-sp1%',
          ],
          special2: [
            'Summon 2 Zephyrs and %move%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%stun%',
          ],
          notes: '',
        },
      ],
    },
    'The Gloom': {
      level: [
        {
          level: 0,
          health: '20xC',
          move: 2,
          attack: 5,
          range: 0,
          special1: [
            '%move% +9',
            '%attack% +9',
          ],
          special2: [
            'Teleport',
            '%attack% +1',
            '%range% 5',
            '%poison%',
            '%wound%',
            '%stun%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '25xC',
          move: 2,
          attack: 5,
          range: 0,
          special1: [
            '%move% +9',
            '%attack% +9',
          ],
          special2: [
            'Teleport',
            '%attack% +1',
            '%range% 5',
            '%poison%',
            '%wound%',
            '%stun%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '29xC',
          move: 2,
          attack: 6,
          range: 0,
          special1: [
            '%move% +9',
            '%attack% +9',
          ],
          special2: [
            'Teleport',
            '%attack% +1',
            '%range% 5',
            '%poison%',
            '%wound%',
            '%stun%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '35xC',
          move: 2,
          attack: 6,
          range: 0,
          special1: [
            '%move% +9',
            '%attack% +9',
          ],
          special2: [
            'Teleport',
            '%attack% +1',
            '%range% 5',
            '%poison%',
            '%wound%',
            '%stun%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '39xC',
          move: 3,
          attack: 7,
          range: 0,
          special1: [
            '%move% +9',
            '%attack% +9',
          ],
          special2: [
            'Teleport',
            '%attack% +1',
            '%range% 5',
            '%poison%',
            '%wound%',
            '%stun%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '46xC',
          move: 3,
          attack: 7,
          range: 0,
          special1: [
            '%move% +9',
            '%attack% +9',
          ],
          special2: [
            'Teleport',
            '%attack% +1',
            '%range% 5',
            '%poison%',
            '%wound%',
            '%stun%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '50xC',
          move: 3,
          attack: 8,
          range: 0,
          special1: [
            '%move% +9',
            '%attack% +9',
          ],
          special2: [
            'Teleport',
            '%attack% +1',
            '%range% 5',
            '%poison%',
            '%wound%',
            '%stun%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '56xC',
          move: 3,
          attack: 9,
          range: 0,
          special1: [
            '%move% +9',
            '%attack% +9',
          ],
          special2: [
            'Teleport',
            '%attack% +1',
            '%range% 5',
            '%poison%',
            '%wound%',
            '%stun%',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
      ],
    },
    'Inox Bodyguard': {
      level: [
        {
          level: 0,
          health: '6xC',
          move: 2,
          attack: 'C',
          range: 0,
          special1: [
            '%move% -1',
            '%attack% -1 %boss-aoe-inox-bodyguard-sp1%',
          ],
          special2: [
            '%move% +0',
            '%attack% +0',
            '%retaliate% 3',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
          ],
          notes: 'C = Number of Characters',
        },
        {
          level: 1,
          health: '7xC',
          move: 2,
          attack: '1+C',
          range: 0,
          special1: [
            '%move% -1',
            '%attack% -1 %boss-aoe-inox-bodyguard-sp1%',
          ],
          special2: [
            '%move% +0',
            '%attack% +0',
            '%retaliate% 3',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
          ],
          notes: 'C = Number of Characters',
        },
        {
          level: 2,
          health: '9xC',
          move: 2,
          attack: '1+C',
          range: 0,
          special1: [
            '%move% -1',
            '%attack% -1 %boss-aoe-inox-bodyguard-sp1%',
          ],
          special2: [
            '%move% +0',
            '%attack% +0',
            '%retaliate% 3',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
          ],
          notes: 'C = Number of Characters',
        },
        {
          level: 3,
          health: '10xC',
          move: 3,
          attack: '2+C',
          range: 0,
          special1: [
            '%move% -1',
            '%attack% -1 %boss-aoe-inox-bodyguard-sp1%',
          ],
          special2: [
            '%move% +0',
            '%attack% +0',
            '%retaliate% 4',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
          ],
          notes: 'C = Number of Characters',
        },
        {
          level: 4,
          health: '11xC',
          move: 3,
          attack: '2+C',
          range: 0,
          special1: [
            '%move% -1',
            '%attack% -1 %boss-aoe-inox-bodyguard-sp1%',
          ],
          special2: [
            '%move% +0',
            '%attack% +0',
            '%retaliate% 4',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
          ],
          notes: 'C = Number of Characters',
        },
        {
          level: 5,
          health: '13xC',
          move: 3,
          attack: '3+C',
          range: 0,
          special1: [
            '%move% -1',
            '%attack% -1 %boss-aoe-inox-bodyguard-sp1%',
          ],
          special2: [
            '%move% +0',
            '%attack% +0',
            '%retaliate% 5',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
          ],
          notes: 'C = Number of Characters',
        },
        {
          level: 6,
          health: '15xC',
          move: 4,
          attack: '3+C',
          range: 0,
          special1: [
            '%move% -1',
            '%attack% -1 %boss-aoe-inox-bodyguard-sp1%',
          ],
          special2: [
            '%move% +0',
            '%attack% +0',
            '%retaliate% 5',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
          ],
          notes: 'C = Number of Characters',
        },
        {
          level: 7,
          health: '17xC',
          move: 4,
          attack: '4+C',
          range: 0,
          special1: [
            '%move% -1',
            '%attack% -1 %boss-aoe-inox-bodyguard-sp1%',
          ],
          special2: [
            '%move% +0',
            '%attack% +0',
            '%retaliate% 5',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
          ],
          notes: 'C = Number of Characters',
        },
      ],
    },
    Jekserah: {
      level: [
        {
          level: 0,
          health: '6xC',
          move: 2,
          attack: 2,
          range: 0,
          special1: [
            'Summon Living Bones',
            '%attack% -1, Target all adjacent enemies',
          ],
          special2: [
            'Summon Living Corpse',
            '%move% -1',
            '%attack% +2',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '7xC',
          move: 2,
          attack: 3,
          range: 0,
          special1: [
            'Summon Living Bones',
            '%attack% -1, Target all adjacent enemies',
          ],
          special2: [
            'Summon Living Corpse',
            '%move% -1',
            '%attack% +2',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '9xC',
          move: 3,
          attack: 3,
          range: 0,
          special1: [
            'Summon Living Bones',
            '%attack% -1, Target all adjacent enemies',
          ],
          special2: [
            'Summon Living Corpse',
            '%move% -1',
            '%attack% +2',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '12xC',
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            'Summon Living Bones',
            '%attack% -1, Target all adjacent enemies',
          ],
          special2: [
            'Summon Living Corpse',
            '%move% -1',
            '%attack% +2',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '13xC',
          move: 4,
          attack: 5,
          range: 0,
          special1: [
            'Summon Living Bones',
            '%attack% -1, Target all adjacent enemies',
          ],
          special2: [
            'Summon Living Corpse',
            '%move% -1',
            '%attack% +2',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '15xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            'Summon Living Bones',
            '%attack% -1, Target all adjacent enemies',
          ],
          special2: [
            'Summon Living Corpse',
            '%move% -1',
            '%attack% +2',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '18xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            'Summon Living Bones',
            '%attack% -1, Target all adjacent enemies',
          ],
          special2: [
            'Summon Living Corpse',
            '%move% -1',
            '%attack% +2',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '22xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            'Summon Living Bones',
            '%attack% -1, Target all adjacent enemies',
          ],
          special2: [
            'Summon Living Corpse',
            '%move% -1',
            '%attack% +2',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: '',
        },
      ],
    },
    'Merciless Overseer': {
      level: [
        {
          level: 0,
          health: '6xC',
          move: 2,
          attack: 'V',
          range: 0,
          special1: [
            'All Scouts act again',
          ],
          special2: [
            'Summon Vermling Scout',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: 'V = Number of Scouts present',
        },
        {
          level: 1,
          health: '8xC',
          move: 2,
          attack: 'V',
          range: 0,
          special1: [
            'All Scouts act again',
          ],
          special2: [
            'Summon Vermling Scout',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: 'V = Number of Scouts present',
        },
        {
          level: 2,
          health: '9xC',
          move: 3,
          attack: 'V',
          range: 0,
          special1: [
            'All Scouts act again',
          ],
          special2: [
            'Summon Vermling Scout',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: 'V = Number of Scouts present',
        },
        {
          level: 3,
          health: '11xC',
          move: 3,
          attack: 'V',
          range: 0,
          special1: [
            'All Scouts act again',
          ],
          special2: [
            'Summon Vermling Scout',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: 'V = Number of Scouts present',
        },
        {
          level: 4,
          health: '12xC',
          move: 4,
          attack: 'V',
          range: 0,
          special1: [
            'All Scouts act again',
          ],
          special2: [
            'Summon Vermling Scout',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: 'V = Number of Scouts present',
        },
        {
          level: 5,
          health: '14xC',
          move: 4,
          attack: 'V',
          range: 0,
          special1: [
            'All Scouts act again',
          ],
          special2: [
            'Summon Vermling Scout',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: 'V = Number of Scouts present',
        },
        {
          level: 6,
          health: '16xC',
          move: 4,
          attack: 'V',
          range: 0,
          special1: [
            'All Scouts act again',
          ],
          special2: [
            'Summon Vermling Scout',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: 'V = Number of Scouts present',
        },
        {
          level: 7,
          health: '19xC',
          move: 4,
          attack: 'V',
          range: 0,
          special1: [
            'All Scouts act again',
          ],
          special2: [
            'Summon Vermling Scout',
          ],
          immunities: [
            '%disarm%',
            '%wound%',
            '%curse%',
            '%stun%',
          ],
          notes: 'V = Number of Scouts present',
        },
      ],
    },
    'Prime Demon': {
      level: [
        {
          level: 0,
          health: '8xC',
          move: 3,
          attack: 4,
          range: 0,
          special1: [
            'Throne moves',
            'Summon Demon (Special 1)',
            '%move% +2',
            '%attack% -1',
          ],
          special2: [
            'Throne moves',
            'Summon Demon (Special 2)',
            '%move% +2',
            '%attack% -1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '9xC',
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            'Throne moves',
            'Summon Demon (Special 1)',
            '%move% +2',
            '%attack% -1',
          ],
          special2: [
            'Throne moves',
            'Summon Demon (Special 2)',
            '%move% +2',
            '%attack% -1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '10xC',
          move: 4,
          attack: 5,
          range: 0,
          special1: [
            'Throne moves',
            'Summon Demon (Special 1)',
            '%move% +2',
            '%attack% -1',
          ],
          special2: [
            'Throne moves',
            'Summon Demon (Special 2)',
            '%move% +2',
            '%attack% -1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '12xC',
          move: 4,
          attack: 6,
          range: 0,
          special1: [
            'Throne moves',
            'Summon Demon',
            '%move% +2',
            '%attack% -1',
          ],
          special2: [
            'Throne moves',
            'Summon Demon',
            '%move% +2',
            '%attack% -1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '14xC',
          move: 5,
          attack: 6,
          range: 0,
          special1: [
            'Throne moves',
            'Summon Demon (Special 1)',
            '%move% +2',
            '%attack% -1',
          ],
          special2: [
            'Throne moves',
            'Summon Demon (Special 2)',
            '%move% +2',
            '%attack% -1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '16xC',
          move: 5,
          attack: 7,
          range: 0,
          special1: [
            'Throne moves',
            'Summon Demon (Special 1)',
            '%move% +2',
            '%attack% -1',
          ],
          special2: [
            'Throne moves',
            'Summon Demon (Special 2)',
            '%move% +2',
            '%attack% -1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '20xC',
          move: 5,
          attack: 7,
          range: 0,
          special1: [
            'Throne moves',
            'Summon Demon (Special 1)',
            '%move% +2',
            '%attack% -1',
          ],
          special2: [
            'Throne moves',
            'Summon Demon (Special 2)',
            '%move% +2',
            '%attack% -1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '22xC',
          move: 5,
          attack: 8,
          range: 0,
          special1: [
            'Throne moves',
            'Summon Demon (Special 1)',
            '%move% +2',
            '%attack% -1',
          ],
          special2: [
            'Throne moves',
            'Summon Demon (Special 2)',
            '%move% +2',
            '%attack% -1',
          ],
          immunities: [
            '%wound%',
            '%poison%',
            '%disarm%',
            '%immobilize%',
            '%muddle%',
            '%stun%',
          ],
          notes: '',
        },
      ],
    },
    'The Sightless Eye': {
      level: [
        {
          level: 0,
          health: '7xC',
          move: 0,
          attack: 5,
          range: 3,
          special1: [
            'Summon Deep Terror',
            '%attack% -3 %boss-aoe-sightless-eye-sp1%',
          ],
          special2: [
            'Summon Deep Terror',
            '%attack% -2 %boss-aoe-sightless-eye-sp2%',
          ],
          immunities: [
            '%stun%',
            '%disarm%',
            '%curse%',
            '%muddle%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '8xC',
          move: 0,
          attack: 6,
          range: 3,
          special1: [
            'Summon Deep Terror',
            '%attack% -3 %boss-aoe-sightless-eye-sp1%',
          ],
          special2: [
            'Summon Deep Terror',
            '%attack% -2 %boss-aoe-sightless-eye-sp2%',
          ],
          immunities: [
            '%stun%',
            '%disarm%',
            '%curse%',
            '%muddle%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '10xC',
          move: 0,
          attack: 6,
          range: 3,
          special1: [
            'Summon Deep Terror',
            '%attack% -3 %boss-aoe-sightless-eye-sp1%',
          ],
          special2: [
            'Summon Deep Terror',
            '%attack% -2 %boss-aoe-sightless-eye-sp2%',
          ],
          immunities: [
            '%stun%',
            '%disarm%',
            '%curse%',
            '%muddle%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '11xC',
          move: 0,
          attack: 7,
          range: 3,
          special1: [
            'Summon Deep Terror',
            '%attack% -3 %boss-aoe-sightless-eye-sp1%',
          ],
          special2: [
            'Summon Deep Terror',
            '%attack% -2 %boss-aoe-sightless-eye-sp2%',
          ],
          immunities: [
            '%stun%',
            '%disarm%',
            '%curse%',
            '%muddle%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '14xC',
          move: 0,
          attack: 7,
          range: 3,
          special1: [
            'Summon Deep Terror',
            '%attack% -3 %boss-aoe-sightless-eye-sp1%',
          ],
          special2: [
            'Summon Deep Terror',
            '%attack% -2 %boss-aoe-sightless-eye-sp2%',
          ],
          immunities: [
            '%stun%',
            '%disarm%',
            '%curse%',
            '%muddle%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '15xC',
          move: 0,
          attack: 8,
          range: 3,
          special1: [
            'Summon Deep Terror',
            '%attack% -3 %boss-aoe-sightless-eye-sp1%',
          ],
          special2: [
            'Summon Deep Terror',
            '%attack% -2 %boss-aoe-sightless-eye-sp2%',
          ],
          immunities: [
            '%stun%',
            '%disarm%',
            '%curse%',
            '%muddle%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '18xC',
          move: 0,
          attack: 8,
          range: 3,
          special1: [
            'Summon Deep Terror',
            '%attack% -3 %boss-aoe-sightless-eye-sp1%',
          ],
          special2: [
            'Summon Deep Terror',
            '%attack% -2 %boss-aoe-sightless-eye-sp2%',
          ],
          immunities: [
            '%stun%',
            '%disarm%',
            '%curse%',
            '%muddle%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '20xC',
          move: 0,
          attack: 9,
          range: 3,
          special1: [
            'Summon Deep Terror',
            '%attack% -3 %boss-aoe-sightless-eye-sp1%',
          ],
          special2: [
            'Summon Deep Terror',
            '%attack% -2 %boss-aoe-sightless-eye-sp2%',
          ],
          immunities: [
            '%stun%',
            '%disarm%',
            '%curse%',
            '%muddle%',
          ],
          notes: '',
        },
      ],
    },
    'Winged Horror': {
      level: [
        {
          level: 0,
          health: '6xC',
          move: 3,
          attack: 3,
          range: 0,
          special1: [
            '%attack% -1, Target all adjacent enemies',
            '%attack% +0, %range% 3',
            'Hatch eggs',
          ],
          special2: [
            'Summon C eggs',
            '%move% -1',
            '%attack% +0',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 1,
          health: '7xC',
          move: 4,
          attack: 3,
          range: 0,
          special1: [
            '%attack% -1, Target all adjacent enemies',
            '%attack% +0, %range% 3',
            'Hatch eggs',
          ],
          special2: [
            'Summon C eggs',
            '%move% -1',
            '%attack% +0',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 2,
          health: '8xC',
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            '%attack% -1, Target all adjacent enemies',
            '%attack% +0, %range% 3',
            'Hatch eggs',
          ],
          special2: [
            'Summon C eggs',
            '%move% -1',
            '%attack% +0',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 3,
          health: '10xC',
          move: 4,
          attack: 4,
          range: 0,
          special1: [
            '%attack% -1, Target all adjacent enemies',
            '%attack% +0, %range% 3',
            'Hatch eggs',
          ],
          special2: [
            'Summon C eggs',
            '%move% -1',
            '%attack% +0',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 4,
          health: '12xC',
          move: 5,
          attack: 4,
          range: 0,
          special1: [
            '%attack% -1, Target all adjacent enemies',
            '%attack% +0, %range% 3',
            'Hatch eggs',
          ],
          special2: [
            'Summon C eggs',
            '%move% -1',
            '%attack% +0',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 5,
          health: '14xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            '%attack% -1, Target all adjacent enemies',
            '%attack% +0, %range% 3',
            'Hatch eggs',
          ],
          special2: [
            'Summon C eggs',
            '%move% -1',
            '%attack% +0',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 6,
          health: '17xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            '%attack% -1, Target all adjacent enemies',
            '%attack% +0, %range% 3',
            'Hatch eggs',
          ],
          special2: [
            'Summon C eggs',
            '%move% -1',
            '%attack% +0',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
        {
          level: 7,
          health: '20xC',
          move: 5,
          attack: 5,
          range: 0,
          special1: [
            '%attack% -1, Target all adjacent enemies',
            '%attack% +0, %range% 3',
            'Hatch eggs',
          ],
          special2: [
            'Summon C eggs',
            '%move% -1',
            '%attack% +0',
          ],
          immunities: [
            '%disarm%',
            '%poison%',
            '%muddle%',
            '%stun%',
            '%curse%',
          ],
          notes: '',
        },
      ],
    },
  },
};
